import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import SEO from '../../../components/SEO';
import Header from '../../../components/Header';
import ContentRow from '../../../components/ContentRow';


const headerColor = '#ae1010';

const SlotsBayPage = ({ data, uri }) => (
  <>
    <SEO
      title="Nouveau casino en ligne qui accepte Neosurf 2024 – 777SlotsBay"
      description="Jette un œil au tout nouveau Casino en ligne qui accepte le mode de paiement de Neosurf – Casino 777SlotBay. Le bonus de bienvenue en neosurf exclusif révélé."
      alternateLanguagePages={[
        {
          path: 'casino/777slotsbay',
          language: 'en'
        },
        {
          path: 'casino/777slotsbay',
          language: 'fr'
        },
        {
          path: 'casino/777slotsbay',
          language: 'de'
        }
      ]}
    />
    <Header backgroundColor={headerColor} />
    <ContentRow backgroundColor={headerColor} padV>
      <div className="grid c-2">
        <div>
          <a href="http://record.777slotsbay-affiliate.com/text/c863cf75000019000002000000000001000001000001" target="_blank" rel="noopener noreferrer">
            <Img fluid={data.header.childImageSharp.fluid} alt="Nouveau Casino amical en ligne Neosurf – 777SlotsBay" />
          </a>
        </div>
        <div>
          <div className="contentBox textAlignCenter">
            <h1 className="h3 smaller textColorDark">Tout Nouveau Casino en ligne qui Accepte Neosurf – 777SlotsBay</h1>
            <p className="textColorLight">Il y a un nouveau joueur dans la ville de casino en ligne et il ne perd pas son temps. Le nom de ce joueur? <strong>Le 777Slotsbay</strong>! Ne t’inquiète pas, tu obtiendras quand même toutes les fonctionnalités auxquelles tu es habitué lorsque tu joues sur une plateforme de jeu supérieure, des milliers de créneaux, des jeux de table, de vidéo  de poker, des cartes à gratter, du bingo et des jeux à croupiers EN DIRECT, de la sécurité et de l’interface utilisateur intuitive de premier ordre. Tu obtiendras également de nombreux bonus incroyables avec une véritable chance de stimuler tes fonds, y compris le <strong>bonus exclusif de casino, le Neosurf</strong>.</p>
          </div>
        </div>
      </div>
    </ContentRow>
    <div className="pageWidth">
      <div className="grid c-2 bigGap">
        <div>
          <div className="contentBox">
            <h2 className="smaller textUppercase">BONUS DE BIENVENUE EXCLUSIF DE NEOSURF DE 777SLOTSBAY</h2>
            <ul className="textColorDark">
              <li>100% jusqu’à 100 USD /EUR &amp; x tours GRATUITS non-pari</li>
              <li>Utilise le code: Neo100+100</li>
              <li>Le dépôt minimum est de 20 USD/EUR</li>
              <li>La cagnotte est fois trente (x30)</li>
            </ul>
            <p className="textAlignCenter">
              <a className="button" href="http://record.777slotsbay-affiliate.com/text/c863cf75000019000002000000000001000001000001" target="_blank" rel="noopener noreferrer">OUVRIR CASINO 777 SLOTS BAY</a>
            </p>
            <p>Tu dois aller simplement au caissier, sélectionne ta méthode de dépôt et suis les instructions pour profiter de cette incroyable offre forfaitaire de bienvenus!</p>
            <p>Le 777Slotsbay est un nouvel joueur de l’affaire de casino en ligne qui a décidé de réaliser de grandes choses. Cependant, cela ne signifie pas que l’équipe derrière la plateforme manque d’expérience, tout au contraire. Les experts de 777Slotsbay ont beaucoup d’histoire dans le créneau de jeu en configurant <strong>ce nouveau casino en ligne sur le bon chemin</strong>, du getgo en apportant à ton écran une large sélection de jeux, de commodité et de vitesse, quel que soit l’appareil, plus la sécurité pare-balles &amp; confidentialité afin que tu puisses déposer &amp; te retirer facilement.</p>
          </div>
        </div>
        <div>
          <Img fluid={data.bonuses.childImageSharp.fluid} alt="Bonus de Neosurf exclusif de casino" />
        </div>
      </div>
    </div>
  </>
)

export default SlotsBayPage;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    header: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/777slotsbay/New-Neosurf-friendly-Online-Casino-777SlotsBay.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 899,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bonuses: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/777slotsbay/Exclusive-Neosurf-Casino-Bonus.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 800,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
